import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

/** Router packages. */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Liabrary. */
import axios from 'axios';

/** JWT Decoder */
import { jwtDecode } from 'jwt-decode';

/** Internal Components. */
import Login from './form/LogIn';
import Logo from './statics/images/logo.png';
import ScheduleTable from './components/functions/user/Schedule/Timeline/ScheduleTable';
import { loadColumns, emptyColumns } from './components/functions/user/Schedule/Timeline/ScheduleTableColumns';
import RenderComponent from './components/theme/RenderComponent';
import './App.css';

/** Mui Premium license key. */
LicenseInfo.setLicenseKey(
    '0cb0ffc103b3fb5fa796878022a8a7afTz0xMDUyNTQsRT0xNzY3ODE2MjM4MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
);

export default function App() {
    /** Destructuring values from useAuth0 hook. */
    const { isAuthenticated, logout, isLoading, user, getAccessTokenSilently } = useAuth0();

    /** State for user ID. */
    const [userId, setUserId] = useState(null);
    /** State for userRole. */
    const [userRole, setUserRole] = useState('Staff');

    /** Set document title. */
    useEffect(() => {
        document.title = 'Insight Logistics | Dispatch';
    }, []);

    /** Styling border progress bar in company theme color. */
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#80b2bf' : '#003a52',
        },
    }));

    /**
     * Get token value from Auth0.
     * Fetch user information via a GET request to the API endpoint.
     * */
    const getUser = async () => {
        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: 'https://insightshippinginc.us.auth0.com/api/v2/',
                },
            });
            const decodedToken = jwtDecode(token);
            const tokenExpiry = decodedToken.exp * 1000;
            const tokenExpiryTime = new Date(tokenExpiry);
            const resp = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: 'sign',
                    email: user.email,
                    first: user.given_name,
                    last: user.family_name,
                    role: user['https://drayage.insightshipping.io/roles']
                        ? user['https://drayage.insightshipping.io/roles'][0]
                        : null,
                },
            });
            sessionStorage.setItem('exp', tokenExpiryTime);
            return resp;
        } catch (e) {
            if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
                logout({
                    returnTo: window.location.origin,
                });
            }
        }
    };

    /** Hook to fetch the userRole and userId of an authenticated user. */
    useEffect(() => {
        if (isAuthenticated && user) {
            const roles = user['https://drayage.insightshipping.io/roles'] ?? [0];
            setUserRole(roles);
            getUser()
                .then((response) => {
                    const resp = response.data['body-json'];
                    const user_id = resp.body.user_id;
                    setUserId(user_id);
                })
                .catch((error) => {});
            return;
        }
    }, [isAuthenticated, user]);

    /** Display loading screen and prevent user from observing internal content until authenticated. */
    if (isLoading) {
        if (!user) {
            return (
                <>
                    <img
                        src={Logo}
                        style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <Box sx={{ width: '100%' }}>
                        <BorderLinearProgress />
                    </Box>
                </>
            );
        }
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/dashboard"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} dashboard={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/deliveryorder"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} deliveryorder={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/loadorder"
                    element={
                        isAuthenticated ? (
                            <ScheduleTable userId={userId} type="loadOrder" columns={loadColumns} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/emptyorder"
                    element={
                        isAuthenticated ? (
                            <ScheduleTable userId={userId} type="emptyOrder" columns={emptyColumns} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/schedule"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} schedule={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/manifest"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} manifest={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/history"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} history={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/rate"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} rate={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/dataentry"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} dataentry={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/payroll"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} payroll={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/myprofile"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} myprofile={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
                <Route
                    path="/logs"
                    element={
                        isAuthenticated ? (
                            <RenderComponent userRole={userRole} userId={userId} logs={true} />
                        ) : (
                            <Login />
                        )
                    }
                />
            </Routes>
        </Router>
    );
}
